export const baseUrl = "https://eranou.vallis.tech";
export const userType = "admin";
export const userUrl = "/user";
export const registerUrl = baseUrl + userUrl + "/register";
export const loginUrl = baseUrl + userUrl + "/login";
export const demande = baseUrl + "/demande";
export const valide = baseUrl + "demande/valide";
export const nonValide = baseUrl +"demande/nonvalide";

export const validerDemande = valide;
export const refuserDemande = nonValide;
export const ajoutCategorie = baseUrl + "categorie/create";
export const listCategorie  = baseUrl + "categorie/";
export const supprimerCategorie = listCategorie;
export const listUserUrl = baseUrl + "/user";
export const livraisonUrl = baseUrl + "/";