import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import { AppBar, Toolbar, IconButton, Typography, Button, Drawer, List, ListItemButton, ListItemText, Collapse, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

const Link = React.forwardRef(function Link(props, ref) {
  return <MuiLink component={RouterLink} ref={ref} {...props} />;
});

const NavBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openDemandes, setOpenDemandes] = useState(false);
  const [openAjouts, setOpenAjouts] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const checkLoginStatus = () => {
    const token = localStorage.getItem('token');
    setIsUserLoggedIn(!!token);
  };

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    if (isUserLoggedIn) {
        try {
            localStorage.removeItem('token');
            checkLoginStatus();
            navigate('/se-connecter');
        } catch (error) {
            console.error('Erreur lors de la déconnexion :', error);
        }
    } else {
        console.log('L\'utilisateur n\'est pas connecté.');
    }
    setOpen(false);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleClickDemandes = () => {
    setOpenDemandes(!openDemandes);
  };

  const handleClickAjouts = () => {
    setOpenAjouts(!openAjouts);
  };

  const handleClickUsers = () => {
    setOpenUsers(!openUsers);
  };

  return (
    <>
      <AppBar position="static" sx={{ flexGrow: 1 }}>
        <Toolbar>
          {isUserLoggedIn && (
            <IconButton edge="start" color="inherit" aria-label="menu" sx={{ marginRight: 2 }} onClick={handleDrawerOpen}>
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Gestion des demandes
          </Typography>
          {!isUserLoggedIn && (
            <Button color="inherit" onClick={() => navigate('/se-connecter')}>
              Se connecter
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {isUserLoggedIn && (
        <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
          <List>
            <ListItemButton onClick={handleClickDemandes}>
              <ListItemText primary={<b style={{  fontSize: '16px' }}>Retrait d'argent</b>} />
            </ListItemButton>
            <Collapse in={openDemandes} timeout="auto" unmountOnExit>
              <List component="div" disablePadding style={{marginLeft: '20px'}}>
                <ListItemButton component={Link} to="/liste-attente">
                  <ListItemText primary="Demandes En Attente" />
                </ListItemButton>
                <ListItemButton component={Link} to="/valide">
                  <ListItemText primary="Demandes Validées" />
                </ListItemButton>
                <ListItemButton component={Link} to="/refuse">
                  <ListItemText primary="Demandes Refusées" />
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={handleClickAjouts}>
              <ListItemText primary={<b style={{  fontSize: '16px' }}>Catégorie de produit</b>} />
            </ListItemButton>
            <Collapse in={openAjouts} timeout="auto" unmountOnExit>
              <List component="div" disablePadding style={{marginLeft: '20px'}}>
                <ListItemButton component={Link} to="/ajouter-categorie">
                  <ListItemText primary="Ajouter une catégorie" />
                </ListItemButton>
                <ListItemButton component={Link} to="/liste-categorie">
                  <ListItemText primary="Liste des catégories" />
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={handleClickUsers}>
              <ListItemText primary={<b style={{  fontSize: '16px' }}>Utilisateurs</b>} />
            </ListItemButton>
            <Collapse in={openUsers} timeout="auto" unmountOnExit>
              <List component="div" disablePadding style={{marginLeft: '20px'}}>
                <ListItemButton component={Link} to="/commercant">
                  <ListItemText primary="Commerçants" />
                </ListItemButton>
                <ListItemButton component={Link} to="/client" >
                  <ListItemText primary="Clients" />
                </ListItemButton>
                <ListItemButton component={Link} to="/admins">
                  <ListItemText primary="Admins" />
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton component={Link} to="/inscription">
              <ListItemText primary="S'inscrire" />
            </ListItemButton>
            <ListItemButton onClick={handleClickOpen}>
              <ListItemText primary="Se déconnecter" />
            </ListItemButton>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Confirmation de déconnexion"}</DialogTitle>
              <DialogContent>
                Êtes-vous sûr de vouloir vous déconnecter ?
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Non
                </Button>
                <Button onClick={handleLogout} color="primary" autoFocus>
                  Oui
                </Button>
              </DialogActions>
            </Dialog>
          </List>
        </Drawer>
      )}
    </>
  );
};

export default NavBar;
