import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../api/auth_client';
import { Container, Grid, Card, CardHeader, CardContent, TextField, Button, Snackbar } from '@mui/material';

const LoginRegister = () => {
  const [form, setForm] = useState({
    phone: '',
    password: ''
  });
  const navigate = useNavigate();

  // Ajoutez un état pour gérer la visibilité et le message du Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  // Fonction pour fermer le Snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  // Modifiez la fonction handleSubmit pour utiliser Snackbar au lieu de alert
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await login(form);
        if (response.data.error) {
          // Définissez le message du Snackbar et rendez-le visible
          setSnackbarMessage('Quelque chose s\'est mal passé');
          
          setOpenSnackbar(true);
        } else {
          console.log(response.data);
          // Stockez le token
          localStorage.setItem('token', response.data.token);
          navigate('/liste-attente');  // Redirigez vers la page d'accueil après une connexion réussie
        }
      } catch (error) {
        console.error(error);
        // Définissez le message du Snackbar et rendez-le visible
        setSnackbarMessage('Identifiants incorrect');
        setOpenSnackbar(true);
      }
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Connexion" />
            <CardContent>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Téléphone"
                  name="phone"
                  onChange={handleChange}
                  required
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Mot de passe"
                  name="password"
                  type="password"
                  onChange={handleChange}
                  required
                />

                <Button type="submit" variant="contained" color="primary" fullWidth>
                  Se connecter
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default LoginRegister;
