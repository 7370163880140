import React, { useEffect, useState } from 'react';
import { nonValide } from '../api/auth_constant';
import axios from 'axios';
import { Container, Grid, Card, CardContent, Typography } from '@mui/material';

const RefuseList = (props) => {
  // eslint-disable-next-line no-unused-vars
  const {title} = props;
  window.addEventListener("load", function(){
    document.title = props.title;
  })
  const [demandesRefusees, setDemandesRefusees] = useState([]);

  useEffect(() => {
    const fetchDemandesRefusees = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token non trouvé');
        }
        const url = nonValide;
        const params = new URLSearchParams();
        params.append('reason', 'true');
        const response = await axios.get(url, {
          headers: { Authorization: `Bearer ${token}` },
          params,
        });
        if (response.status !== 200) {
          throw new Error(`Erreur lors de la récupération des demandes refusées: ${response.statusText}`);
        }
        setDemandesRefusees(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des demandes refusées: ', error);
      }
    };
  
    fetchDemandesRefusees();
  }, []); // Ajoutez les dépendances ici si nécessaire
  

  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h4" component="h2" gutterBottom>
            Demandes Refusées
          </Typography>
          {demandesRefusees.length > 0 ? (
            demandesRefusees.map((demande) => (
              <Card key={demande.id} sx={{ marginBottom: 2 }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {demande.phone}
                  </Typography>
                  <Typography variant="body2">
                    Montant: {demande.amount} FCFA
                  </Typography>
                  {demande.raison && (
                    <Typography variant="body2" color="error">
                      Motif du refus: {demande.raison}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography align="center">Aucune demande refusée.</Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default RefuseList;