import React, { useState } from 'react';
import { register } from '../api/auth_client';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Card, CardHeader, CardContent, TextField, Button, FormControl, InputLabel, Select, MenuItem, Snackbar } from '@mui/material';

const UserRegister = (props) => {
  // eslint-disable-next-line no-unused-vars
  const {title} = props;
  window.addEventListener("load",function(){
    document.title = props.title;
  })
  const [form, setForm] = useState({
    lastname: '',
    firstname: '',
    email: '',
    phone: '',
    password: '',
    userType: ''
  });
  const navigate = useNavigate();

  // Ajoutez un état pour gérer la visibilité et le message du Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Fonction pour fermer le Snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  // Modifiez la fonction handleSubmit pour utiliser Snackbar au lieu de console.error
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await register(form, form.userType);
      console.log(response.data);
      localStorage.setItem('token', response.data.token);
      navigate('/liste-attente');
    } catch (error) {
      console.error('Erreur lors de l\'inscription: ', error);
      // Vérifiez si l'erreur a une réponse
      if (error.response) {
        // Utilisez le statut et les données de la réponse pour créer un message d'erreur détaillé
        const errorMessage = `Une erreur est survenue lors de l'inscription. Statut : ${error.response.status}. Message : ${error.response.data}.`;
        setSnackbarMessage(errorMessage);
      } else {
        // Si l'erreur n'a pas de réponse, utilisez un message d'erreur générique
        setSnackbarMessage('Une erreur est survenue lors de l\'inscription.');
      }
      setOpenSnackbar(true);
    }
  };
  

  return (
    <Container sx={{ mt: 4 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Inscription" />
            <CardContent>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Nom"
                  name="lastname"
                  onChange={handleChange}
                  required
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Prénom"
                  name="firstname"
                  onChange={handleChange}
                  required
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  required
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Téléphone"
                  name="phone"
                  onChange={handleChange}
                  required
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Mot de passe"
                  name="password"
                  type="password"
                  onChange={handleChange}
                  required
                />

                <FormControl fullWidth margin="normal" required>
                  <InputLabel>Type d'utilisateur</InputLabel>
                  <Select
                    name="userType"
                    onChange={handleChange}
                    defaultValue=""
                  >
                    <MenuItem value="">
                      <em>Sélectionnez...</em>
                    </MenuItem>
                    <MenuItem value="admin">admin</MenuItem>
                  </Select>
                </FormControl>

                <Button type="submit" variant="contained" color="primary" fullWidth>
                  S'inscrire
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default UserRegister;
