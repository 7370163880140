import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import LoginRegister from './components/LoginRegister';
import AttenteList from './components/AttenteList';
import ValideList from './components/ValideList';
import RefuseList from './components/RefuseList';
import UserRegister from './components/UserRegister';
import AjouterCategorie from './components/AjouterCategorie';
import { Container } from '@mui/material';
import CategorieList from './components/CategorieList';
import MerchantsList from './components/MerchantsList';
import ClientsList from './components/ClientsList';
import AdminsList from './components/AdminsList';


const App = () => {
  return (
    <Router>
      <NavBar />
      <Container>
        <Routes>
          <Route path="/" element={<UserRegister title="Inscription"/>} />
          <Route path="/liste-attente" element={<AttenteList title="Liste d'attente" />} />
          <Route path="/se-connecter" element={<LoginRegister title="Login" />} />
          <Route path="/valide" element={<ValideList title="Liste des validations" />} />
          <Route path="/refuse" element={<RefuseList title="Liste des refus"/>} />
          <Route path="/ajouter-categorie" element={<AjouterCategorie title="Ajouter une catégorie" />} />
          <Route path="/liste-categorie" element={<CategorieList title="Liste des catégories"/>} />
          <Route path="/inscription" element={<UserRegister title="Inscription"/>} />
          <Route path="/commercant" element={<MerchantsList title="Marchands" />}/>
          <Route path="/client" element={<ClientsList title="Clients"/>}/>
          <Route path="/admins" element={<AdminsList title="Administrateurs"/>}/>
        </Routes>
      </Container>
    </Router>
  );
};

export default App;
