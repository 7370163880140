import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Card, CardHeader, CardContent, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import {baseUrl, ajoutCategorie} from '../api/auth_constant';

// Créez une instance d'axios
const api = axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`
  }
});

const AjouterCategorie = (props) => {
  // eslint-disable-next-line no-unused-vars
  const {title} = props;
  window.addEventListener("load", function(){
    document.title = props.title;
  })
  const [libelle, setLibelle] = useState('');
  const [description, setDescription] = useState('');
  const [photo, setPhoto] = useState(null);
  const [preview, setPreview] = useState(null);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('libelle', libelle);
    formData.append('description', description);
    formData.append('photo', photo);

    try {
      await api.post(ajoutCategorie, formData);
      setOpen(true);
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la catégorie: ', error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    navigate('/liste-categorie');
  };

  const handlePhotoChange = (event) => {
    setPhoto(event.target.files[0]);
    setPreview(URL.createObjectURL(event.target.files[0]));
  };

  return (
    <Container style={{ marginTop: '20px' }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Ajouter une catégorie" />
            <CardContent>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Libellé"
                  value={libelle}
                  onChange={(e) => setLibelle(e.target.value)}
                  required
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Description"
                  multiline
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />

                <Button
                  variant="contained"
                  component="label"
                  fullWidth
                  margin="normal"
                  style={{ marginBottom: '10px', backgroundColor: '#3f51b5', color: 'white' }}
                >
                  Upload Photo
                  <input
                    type="file"
                    hidden
                    onChange={handlePhotoChange}
                    required
                  />
                </Button>
                {preview && (
                  <div style={{ marginBottom: '10px' }}>
                    <p>Image preview:</p>
                    <img src={preview} alt="preview" style={{ width: '100%', height: 'auto', borderRadius: '5px' }} />
                  </div>
                )}
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  Ajouter
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Catégorie ajoutée avec succès"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            La catégorie a été ajoutée avec succès. Voulez-vous en ajouter une autre ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fermer
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            Ajouter une autre catégorie
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AjouterCategorie;
