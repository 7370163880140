import axios from "axios";
import { registerUrl, loginUrl } from '../api/auth_constant';

let tokenTimeout;

const storeToken = async (token) => {
  try {
    localStorage.setItem('token', token);
    resetTokenTimeout();
  } catch (e) {
    console.error("Error storing token:", e);
  }
};

const resetTokenTimeout = () => {
  if (tokenTimeout) {
    clearTimeout(tokenTimeout);
  }
  tokenTimeout = setTimeout(() => {
    localStorage.removeItem('token');
  }, 15 * 60 * 1000); // 15 minutes
};

const handleError = (e) => {
  console.error(e);
  if (e.response) {
    const errorMessage = e.response.data.message || 'An error occurred';
    alert(`Error: ${errorMessage}`);
    console.log(e.response.data);
    console.log(e.response.status);
    console.log(e.response.headers);
  } else if (e.request) {
    console.log(e.request);
  } else {
    console.log('Error', e.message);
  }
};

export const register = async (body, userType) => {
  try {
    const res = await axios.post(registerUrl, body);
    console.log(res.data, res.status);
    
    // Store the token only for admin users
    if (userType === 'admin' && res.data.token) {
      storeToken(res.data.token);
    }
    
    return res;
  } catch (e) {
    handleError(e);
    throw e;
  }
};

export const login = async (body) => {
  try {
    const res = await axios.post(loginUrl, body);
    console.log(res.data, res.status);

    // Store the token after a successful login
    if (res.data.token) {
      storeToken(res.data.token);
    }

    return res;
  } catch (e) {
    handleError(e);
    throw e;
  }
};