import UsersList from './UsersList';

const ClientsList = (props) => {
  
  // eslint-disable-next-line no-unused-vars
  const {title} = props;
  window.addEventListener("load", function(){
    document.title = props.title;
  })
  return <UsersList userType="client" />;
};

export default ClientsList;
