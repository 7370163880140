import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { listUserUrl } from '../api/auth_constant';
import { Container, Grid, Card, CardHeader, CardContent, TextField, Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Select, MenuItem } from '@mui/material';

const UsersList = ({ userType }) => {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token non trouvé');
        }
        const response = await axios.get(listUserUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        setUsers(response.data.filter(user => user.userType === userType));
      } catch (error) {
        console.error('Erreur lors de la récupération des utilisateurs: ', error);
      }
    };

    fetchUsers();
  }, [userType]);

  const handleDelete = (id) => {
    setSelectedUser(users.find(user => user.id === id));
    setConfirmDelete(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedUser && selectedUser.id) {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token non trouvé');
        }
        await axios.delete(`${listUserUrl}${selectedUser.id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        alert('Utilisateur supprimé avec succès');
        // Update users state
        setUsers(users.filter(user => user.id !== selectedUser.id));
      } catch (error) {
        console.error('Erreur lors de la suppression de l\'utilisateur: ', error);
      }
      setConfirmDelete(false); // Close the confirmation dialog
    } else {
      console.error('Erreur: Impossible de supprimer, utilisateur non sélectionné.');
    }
  };

  const handleOpen = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token non trouvé');
      }
      await axios.put(`${listUserUrl}${selectedUser.id}`, selectedUser, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      alert('Utilisateur modifié avec succès');
      setOpen(false);
      const response = await axios.get(listUserUrl, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Erreur lors de la modification de l\'utilisateur: ', error);
    }
  };

  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          {users.map((user) => (
            <Card key={user.id} sx={{ my: 2, p: 2 }}>
              <CardHeader title={user.firstname + ' ' + user.lastname} />
              <CardContent>
                <Typography variant="body1" style={{ color: '#3f51b5' }}>Email: {user.email}</Typography>
                <Typography variant="body1" style={{ color: '#3f51b5' }}>Phone: {user.phone}</Typography>
                <Typography variant="body1" style={{ color: '#3f51b5' }}>User Type: {user.userType}</Typography>
                <Button variant="contained" color="primary" onClick={() => handleOpen(user)} sx={{ mr: 1, mt: 1 }}>
                  Modifier
                </Button>
                <Button variant="contained" color="secondary" onClick={() => handleDelete(user.id)} sx={{ mt: 1 }}>
                  Supprimer
                </Button>
              </CardContent>
            </Card>
          ))}
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Modifier un utilisateur</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label="Prénom"
            value={selectedUser?.firstname}
            onChange={(e) => setSelectedUser({ ...selectedUser, firstname: e.target.value })}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Nom"
            value={selectedUser?.lastname}
            onChange={(e) => setSelectedUser({ ...selectedUser, lastname: e.target.value })}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            value={selectedUser?.email}
            onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Téléphone"
            value={selectedUser?.phone}
            onChange={(e) => setSelectedUser({ ...selectedUser, phone: e.target.value })}
            required
          />
          <FormControl fullWidth margin="normal">
            <Select
              value={selectedUser?.userType}
              onChange={(e) => setSelectedUser({ ...selectedUser, userType: e.target.value })}
              required
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="client">Client</MenuItem>
              <MenuItem value="marchand">Marchand</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleUpdate} color="primary">
            Modifier
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <DialogTitle>Confirmer la suppression</DialogTitle>
        <DialogContent>
          <Typography>Êtes-vous sûr de vouloir supprimer cet utilisateur ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UsersList;
