import React, { useEffect, useState } from 'react';
import { valide } from '../api/auth_constant';
import axios from 'axios';
import { Container, Grid, Card, CardContent, Typography } from '@mui/material';

const ValideList = (props) => {
  // eslint-disable-next-line no-unused-vars
  const {title} = props;
  window.addEventListener("load", function(){
    document.title = props.title;
  })
  const [demandesValidees, setDemandesValidees] = useState([]);

  useEffect(() => {
    const fetchDemandesValidees = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token non trouvé');
        }
        const url = valide;
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` }});
        if (response.status !== 200) {
          throw new Error(`Erreur lors de la récupération des demandes validées: ${response.statusText}`);
        }
        setDemandesValidees(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des demandes validées: ', error);
      }
    };
  
    fetchDemandesValidees();
  }, []); // Ajoutez les dépendances ici si nécessaire
  

  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h4" component="h2" gutterBottom>
            Demandes Validées
          </Typography>
          {demandesValidees.length > 0 ? (
            demandesValidees.map((demande) => (
              <Card key={demande.id} sx={{ marginBottom: 2 }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {demande.merchantId}
                  </Typography>
                  <Typography variant="body2">
                    Montant: {demande.amount} FCFA
                  </Typography>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography align="center">Aucune demande validée.</Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ValideList;
