import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Card, CardContent, Typography, Container, Box } from '@mui/material';
import axios from 'axios';
import { demande } from '../api/auth_constant';

const AttenteList = (props) => {

  // eslint-disable-next-line no-unused-vars
  const {title} = props;
  window.addEventListener("load", function(){
    document.title = props.title;
  })
  const [demandes, setDemandes] = useState([]);
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [raison, setRaison] = useState(''); // Ajout d'un état pour le motif

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!token) {
      setModalContent('Connectez-vous pour accéder au service !');
      setShow(true);
      return;
    }

    const fetchDemandesEnAttente = async () => {
      try {
        if (!token) {
          throw new Error('Token non trouvé');
        }
        const url = demande;
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` }});
        if (response.status !== 200) {
          throw new Error(`Erreur lors de la récupération des demandes en attente: ${response.statusText}`);
        }
        setDemandes(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des demandes en attente: ', error);
      }
    };

    fetchDemandesEnAttente();
  }, [token]); // Ajoutez le token comme dépendance

  const handleValider = async (id) => {
    try {
      if (!token) {
        throw new Error('Token non trouvé');
      }
      const url = `/api/valider-demande/${id}`; // Endpoint pour valider la demande
      const response = await axios.put(url, { statut: 'valide' }, { headers: { Authorization: `Bearer ${token}` } });
      if (response.status !== 200) {
        throw new Error(`Erreur lors de la validation de la demande: ${response.statusText}`);
      }
      // Mettre à jour l'état des demandes après validation
      setDemandes(demandes.map(demande => demande.id === id ? { ...demande, statut: 'valide' } : demande));
      setModalContent('Demande validée');
      setShow(true);
    } catch (error) {
      console.error('Erreur lors de la validation de la demande: ', error);
    }
  };

  const handleRefuser = async (id) => {
    try {
      if (!token) {
        throw new Error('Token non trouvé');
      }
      const url = `/api/refuser-demande/${id}`;
      const response = await axios.put(url, { statut: 'refuse', raison }, { headers: { Authorization: `Bearer ${token}` } });
      if (response.status !== 200) {
        throw new Error(`Erreur lors du refus de la demande: ${response.statusText}`);
      }
      setDemandes(demandes.filter((demande) => demande.id !== id));
      setModalContent('Demande supprimée');
      setShow(true);
    } catch (error) {
      console.error('Erreur lors du refus de la demande: ', error);
    }
  };

  const handleClose = () => {
    setShow(false);
    setRaison(''); // Réinitialisez le motif lorsque la boîte de dialogue est fermée
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {token && <Typography variant="h4">Demandes En Attente</Typography>}
        {!token && <Typography variant="h4">Connectez-vous pour accéder au service !</Typography>}
      </Box>
      {demandes.length > 0 && (
        demandes.map((demande) => (
          <Box key={demande.id} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Card sx={{ width: '100%', maxWidth: 500 }}>
              <CardContent>
                <Typography variant="h5">{demande.merchantId}</Typography>
                <Typography variant="body1">Montant: {demande.amount} FCFA</Typography>
                <Typography variant="body1">Numéro: {demande.phone}</Typography>
                <Button variant="contained" color="success" sx={{ mt: 2, mr: 1 }} onClick={() => handleValider(demande.id)}>✓ Valider</Button>
                <Button variant="contained" color="error" sx={{ mt: 2 }} onClick={() => handleRefuser(demande.id)}>✗ Refuser</Button>
              </CardContent>
            </Card>
          </Box>
        ))
      )}
      <Dialog open={show} onClose={handleClose}>
        <DialogTitle>Notification</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{modalContent}</Typography>
          {modalContent === 'Demande supprimée' && (
            <>
              <TextField
                value={raison}
                onChange={(e) => setRaison(e.target.value)}
                placeholder="Entrez le motif du refus"
                multiline
                fullWidth
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AttenteList;
