import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUrl, listCategorie, supprimerCategorie } from '../api/auth_constant';
import { Container, Grid, Card, CardHeader, CardContent, TextField, Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';


const CategorieList = (props) => {

  // eslint-disable-next-line no-unused-vars
  const {title} = props;
  window.addEventListener("load", function(){
    document.title = props.title
  })
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false); // Nouvel état pour la confirmation de suppression
  
  

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token non trouvé');
        }
        const response = await axios.get(listCategorie, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        setCategories(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des catégories: ', error);
      }
    };

    fetchCategories();
  }, []);

  const handleDelete = (id) => {
    setSelectedCategory(categories.find(categorie => categorie.id === id));
    setConfirmDelete(true); // Open the confirmation dialog
  };
  
  const handleConfirmDelete = async () => {
    if (selectedCategory && selectedCategory.id) {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token non trouvé');
        }
        await axios.delete(`${supprimerCategorie}${selectedCategory.id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        alert('Catégorie supprimée avec succès');
        // Update categories state
        setCategories(categories.filter(categorie => categorie.id !== selectedCategory.id));
      } catch (error) {
        console.error('Erreur lors de la suppression de la catégorie: ', error);
      }
      setConfirmDelete(false); // Close the confirmation dialog
    } else {
      console.error('Erreur: Impossible de supprimer, catégorie non sélectionnée.');
    }
  };
  
  const handleOpen = (categorie) => {
    setSelectedCategory(categorie);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token non trouvé');
      }
      await axios.put(`${listCategorie}${selectedCategory.id}`, selectedCategory, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      alert('Catégorie modifiée avec succès');
      setOpen(false);
      const response = await axios.get(listCategorie, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setCategories(response.data);
    } catch (error) {
      console.error('Erreur lors de la modification de la catégorie: ', error);
    }
  };

  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          {categories.map((categorie) => (
            <Card key={categorie.id} sx={{ my: 2, p: 2 }}>
              <CardHeader title={categorie.libelle} />
              <CardContent>
              <Typography variant="body1" style={{ color: '#3f51b5' }}>Description: {categorie.description.length > 100 ? categorie.description.substring(0, 100) + '...' : categorie.description}</Typography>
              {categorie.photo && typeof categorie.photo === 'string' ?
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                  <img src={baseUrl+categorie.photo} alt={categorie.libelle} style={{ maxWidth: '100%', maxHeight: '100px' }} />
                </div>
                : null}
              <Button variant="contained" color="primary" onClick={() => handleOpen(categorie)} sx={{ mr: 1, mt: 1 }}>
              Modifier
              </Button>
              <Button variant="contained" color="secondary" onClick={() => handleDelete(categorie.id)} sx={{ mt: 1 }}>
              Supprimer
              </Button>
              </CardContent>
              </Card>

          ))}
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Modifier une catégorie</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label="Libellé"
            value={selectedCategory?.libelle}
            onChange={(e) => setSelectedCategory({ ...selectedCategory, libelle: e.target.value })}
            required
          />

          <TextField
            fullWidth
            margin="normal"
            label="Description"
            multiline
            value={selectedCategory?.description}
            onChange={(e) => setSelectedCategory({ ...selectedCategory, description: e.target.value })}
            required
          />

          <Button
            variant="contained"
            component="label"
            fullWidth
            margin="normal"
          >
            Upload Photo
            <input
              type="file"
              hidden
              onChange={(e) => setSelectedCategory({ ...selectedCategory, photo: e.target.files[0] })}
              required
            />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleUpdate} color="primary">
            Modifier
          </Button>
        </DialogActions>
      </Dialog><Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <DialogTitle>Confirmer la suppression</DialogTitle>
        <DialogContent>
          <Typography>Êtes-vous sûr de vouloir supprimer cette catégorie ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CategorieList;